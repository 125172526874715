<template>
  <div class="main">
    <div id="mapDiv"
         class="mapDiv"
         ref="mapDiv">
    </div>
    <img class="map-logo"
         src="../../assets/main/map_logo.png">
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '../../utils/tMap'
export default {
  components: {},
  data () {
    return {
      // 地图
      T: '',
      zoom: 0,
      map: '',
      markerArr: [],
    };
  },
  computed: {
    ...mapGetters({
      'mapCenterLnglat': 'getMapCenterLnglat',
    }),
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      if (this.$route.query.token) {
        let token = this.$route.query.token.replace(/%2B/g, '+').replace(/%2F/g, '/')
        this.$store.commit('setToken', token)
      } else {
        console.log('没有传入token')
      }
      this.createMap()
      window.getMapMarkerList = this.getMapMarkerList
      window.closePoptip = this.closePoptip
    },
    createMap () {
      this.$Spin.show()
      tMap.init().then((T) => {
        this.$Spin.hide();
        this.T = T
        this.zoom = 15
        this.map = new T.Map('mapDiv')
        let lnglat
        if (this.mapCenterLnglat) {
          let mapCenterLnglat = this.mapCenterLnglat.split(',')
          lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
        } else {
          lnglat = new T.LngLat(119.130974, 36.706688)
        }
        this.map.centerAndZoom(lnglat, this.zoom)
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败，请刷新浏览器')
      })
    },
    // 获取地图标记点列表
    getMapMarkerList (status) {
      let params = {
        page: 1,
        size: 999,
        statuses: status ? status : ''
      }
      this.$http.getPatrolCaseList(params).then((res) => {
        if (res.code === 200) {
          this.map.clearOverLays()
          let data = res.result;
          let caseMarkerArr = data.cases.map(item => {
            item.type = '案件'
            item.marker = this.createMarker(item)
            this.drawMarker(item)
            return item
          })
          this.markerArr = [...caseMarkerArr]
        }
      })
    },
    // 生成标记点
    createMarker (obj) {
      let objJson = JSON.stringify(obj)
      let icon = ''
      let lng = obj.lng
      let lat = obj.lat
      switch (obj.type) {
        case '案件':
          if (obj.isOverdue == 1) {
            icon = require('../../assets/main/marker_case_overdue.png')
          } else {
            icon = require('../../assets/main/marker_case_normal.png')
          }
          break;
        default:
          console.log(obj)
          return
      }
      //创建图片对象
      let iconObj = new T.Icon({
        iconUrl: icon,
        iconSize: new T.Point(33, 33),
        iconAnchor: new T.Point(10, 25)
      })
      let marker = new T.Marker(new T.LngLat(lng, lat), { icon: iconObj })
      marker.addEventListener('click', (e) => {
        this.openPoptip(obj, e.lnglat)
        console.log(objJson);
        // 调用app的方法
        window.androidProxy.getIncident(objJson)
      })
      return marker
    },
    // 在地图上绘制标记点
    drawMarker (obj) {
      //向地图上添加自定义标注
      this.map.addOverLay(obj.marker)
    },
    // 打开气泡
    async openPoptip (obj, lnglat) {
      if (!obj.address) {
        obj.address = await this.getAddress(lnglat)
      }
      let content = this.getContent(obj)
      let markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(7, -20), autoPan: true, closeButton: false, closeOnClick: true });
      this.map.openInfoWindow(markerInfoWin, lnglat)
    },
    // 关闭气泡
    closePoptip () {
      console.log('关闭信息窗');
      this.map.closeInfoWindow()
    },
    // 逆地理编码
    async getAddress (lnglat) {
      const geocode = new T.Geocoder()
      return new Promise((resolve) => {
        geocode.getLocation(lnglat, (e) => {
          if (e.getStatus() === 0) {
            const addressComponent = e.getAddressComponent()
            resolve(addressComponent.address)
          } else {
            resolve(lnglat.lng + ',' + lnglat.lat)
          }
        })
      })
    },
    // 获取气泡content
    getContent (obj) {
      let content = ''
      switch (obj.type) {
        case '案件':
          let status = '';
          switch (obj.status) {
            case 1:
              status = '待分派';
              break;
            case 2:
              status = '待处理';
              break;
            case 3:
              status = '待结案';
              break;
            case 4:
              status = '已结案';
              break;
            case 5:
              status = '废弃工单';
              break;
          }
          content = `
              <div class="poptip">
                <div class="poptip-title">
                  <p class="event">
                    ${obj.caseIssueChildName}-${status || '--'}
                  </p>
                  <span class="${obj.isOverdue == 1 ? 'alert' : ''}" } style="${obj.isOverdue == 1 ? '' : 'display:none'};">
                    逾期
                  </span>
                </div>
              </div>
            `
          break;
      }
      return content;
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  .mapDiv {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .map-logo {
    z-index: 100;
    position: absolute;
    left: 10px;
    bottom: 30px;
  }
}
::v-deep {
  .tdt-infowindow {
    .tdt-infowindow-tip-container {
      .tdt-infowindow-tip {
        background: rgba(0, 0, 0, 0.85);
      }
    }
    .tdt-infowindow-content-wrapper {
      background: rgba(0, 0, 0, 0.85);
      color: #83898e;
      border-radius: 6px;
      cursor: pointer;
      .tdt-infowindow-content {
        width: 160px;
        .poptip {
          .poptip-treename {
            margin-bottom: 10px;
          }
          .poptip-title {
            display: flex;
            justify-content: space-between;
            // padding-bottom: 10px;
            // margin-bottom: 10px;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            .event {
              color: #fff;
            }
            span {
              margin-left: auto;
              padding: 2px 6px;
              border-radius: 10px;
              font-size: 12px;
            }
            .on {
              color: #32b16c;
              background: rgba(50, 177, 108, 0.2);
            }
            .off {
              color: #84919e;
              background: rgba(132, 145, 158, 0.2);
            }
            .task {
              color: #1890ff;
              background: rgba(24, 144, 255, 0.2);
            }
            .alert {
              color: #c30b19;
              background: rgba(195, 11, 25, 0.2);
            }
          }
          .poptip-cont {
            padding: 10px 0;
          }
          .poptip-speed {
            margin-bottom: 10px;
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
            color: #fff;
            display: flex;
            // justify-content: space-between;
            p {
              flex: 1;
            }
          }
          .btn-box {
            display: flex;
            justify-content: space-between;
            .item {
              flex: 1;
              background: rgba(255, 255, 255, 0.1);
              color: #eeeeee;
              border-radius: 4px;
              text-align: center;
              line-height: 32px;
              height: 32px;
              cursor: pointer;
              a {
                color: #fff;
              }
            }
            .item:last-child {
              margin-left: 8px;
            }
          }
          .area-box {
            display: flex;
            margin-bottom: 6px;
            align-items: center;
            justify-content: center;
            div {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 49%;
              p {
                margin-bottom: 8px;
              }
              span {
                color: #eee;
                font-size: 16px;
              }
            }
            div:nth-of-type(1) {
              border-right: 1px solid rgba(255, 255, 255, 0.2);
            }
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
}
</style>